import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { User, Company } from '../models';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  _users: Observable<User[]>;
  _companies: Observable<Company[]>;

  constructor(private afs: AngularFirestore) {
    this._companies = this.afs.collection('companies').valueChanges();

  }

  ngOnInit() {
    this._users = this.afs.collection('users2').valueChanges();
  }

  onChange(company) {
    console.log(company);
    this._users = this.afs.collection('users2', ref => {
      if (!!!company) return ref;
      return ref.where('company', '==', this.afs.doc(`companies/${company.id}`).ref)
    }).valueChanges()
  }
}