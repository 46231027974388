import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from "@angular/fire/auth";

import { UserComponent } from './user/user.component';
import { CompanyComponent } from './company/company.component';
import { UserListComponent } from './user-list/user-list.component';

import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';

import { NetworkInterface } from '@ionic-native/network-interface/ngx';


@NgModule({
  declarations: [AppComponent, UserComponent, CompanyComponent, UserListComponent],
  entryComponents: [],
  imports: [
  	BrowserModule,
  	IonicModule.forRoot(),
  	AppRoutingModule,
  	AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule

  ],
  providers: [
  	{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BluetoothSerial,
    NetworkInterface
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
