import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import firebase from 'firebase';
import { User, Company } from '../models';



@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  company: Company ={};
  constructor(private afs: AngularFirestore) {

  }

  ngOnInit() {
  }

  createCompany() {
    const companyId = this.afs.createId();
    this.company.id = companyId;
    this.afs.doc(`companies/${companyId}`).set(this.company);
  }
}