// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
	apiKey: "AIzaSyB6aIknrzo-ZFT3xiTeReZZle2ZW8vfn1o",
	authDomain: "moapp-9395.firebaseapp.com",
	projectId: "moapp-9395",
	storageBucket: "moapp-9395.appspot.com",
	messagingSenderId: "82987027668",
	appId: "1:82987027668:web:c8a0c29994266136d37f41",
	measurementId: "G-414JQV83JV"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
