import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { User, Company } from '../models';



@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  user: User = {};
  _companies: Observable<Company[]>;
  constructor(private afs: AngularFirestore) {

  }

  ngOnInit() {
    this._companies = this.afs.collection('companies').valueChanges();
  }

  onCompanyChange(event) {
    this.user.company = this.user.companyId ? this.afs.doc(`companies/${this.user.companyId}`).ref : null;
  }

  createUser() {
    if (!!!this.user.company) return;
    const userId = this.afs.createId();
    this.user.id = userId;
    this.afs.doc(`users2/${userId}`).set(this.user);
  }



}