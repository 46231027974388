import { Component } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import firebase from 'firebase';

 class User {
  id: string;
  name: string;
  companyId: string;
  company: firebase.firestore.DocumentReference;
}
  class Company {
  name: string;
  id: string;  
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
	constructor(private afs: AngularFirestore) {
	}
}